import axios from 'axios';

// Set the base URL for Axios requests
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
// axios.defaults.baseURL = 'https://shareapp2.urskart.com/api';
 axios.defaults.baseURL = 'https://go.firstmarket.info/api';

// axios.defaults.headers.common['Authorization'] = 'Bearer YourAuthToken';
// axios.defaults.headers.common['Content-Type'] = 'application/json';

export default axios;
