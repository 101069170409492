import React, { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import Register from "./auth/Register";
import useAuthUser from "./auth/AuthUser"; // Import useAuthUser here

// Define the expiry date here
const expiryDate = new Date('2024-10-03'); // Set your expiry date

function App() {
  const { getToken } = useAuthUser(); // Call useAuthUser inside the functional component
  const [isExpired, setIsExpired] = useState(false); // State to track if the app has expired

  useEffect(() => {
    // Check if the current date is past the expiry date
    const currentDate = new Date();
    if (currentDate > expiryDate) {
      setIsExpired(true); // Set expired status
    }
  }, []);

  // If the app is expired, show an expiration message
  if (isExpired) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Application Expired</h1>
        <p>Please contact support to renew your access.</p>
        {/* <h2>7909230201</h2> */}
      </div>
    );
  }

  // If no token is present, show the login page
  if (!getToken()) {
    return <Login />;
  }

  // If the app is not expired and user is logged in, render the app
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        <Route path="/tradeRegister" element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;
